import React, { useContext } from 'react';
import '../grillade.css';
import { Router } from '@rememberfootball/simple-admin-generator';
import { Container } from '@material-ui/core';
import Bar from './Bar';
import UserContext from '../contexts/UserContext';
import definitions from '../form-definitions/definitions';
import SecurityModel from '../models/SecurityModel';
import NotificationsWrapper from './NotificationsWrapper';

const Backoffice = () => {
    const [user, setUser] = useContext(UserContext);

    const login = async (username, password) => {
        const response = await SecurityModel.login(username, password);

        localStorage.setItem('token', response.token);
        setUser(await SecurityModel.me());
    };

    return <div className="App">
        <Bar />
        <Container>
            <NotificationsWrapper />
            <Router
                definitions={definitions}
                withAuth={true}
                user={user}
                login={login}
            />
        </Container>
    </div>;
};

export default Backoffice;
