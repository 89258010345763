import React from 'react';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';

export default props => {
    return <CollectionItem onDelete={props.onDelete} className="autogrid has-gutter">
        <AutocompleteSelect
            label="Player"
            options={props.playerOptions}
            optionLabel={o => `${o.firstName} ${o.lastName}`}
            value={props.value.player || null}
            variant="standard"
            onChange={v => props.onChange('player', v)}
        />
        <AutocompleteSelect
            label="Type"
            options={props.typeOptions}
            optionLabel={o => o.name}
            value={props.value.type || null}
            variant="standard"
            onChange={v => props.onChange('type', v)}
        />
    </CollectionItem>;
};
