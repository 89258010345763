import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Toolbar } from '@material-ui/core';
import UserContext from '../contexts/UserContext';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'right',
        color: '#FFFFFF'
    },
}));

export default function Bar() {
    const classes = useStyles();
    const [user, setUser] = useContext(UserContext);

    const handleLogoutClick = () => {
        localStorage.clear();
        setUser(null);
        window.location.href = '/login';
    };

    return (
        <div>
            <AppBar style={{ background: '#ab3013' }} position="static">
                <Toolbar>
                    <Avatar className={classes.avatar} edge="start" src="/bg.svg" />
                    <div className={classes.title}>
                        { user ? (
                            <>
                                { user.username }
                                <Button color="inherit" onClick={handleLogoutClick}>Logout</Button>
                            </>
                        ) : null }
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
