import Input from '../components/form/Input';
import ArticleModel from '../models/ArticleModel';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import ArticleCategoryModel from '../models/ArticleCategoryModel';
import PictureInput from '../components/form/PictureInput';
import Checkbox from '../components/form/Checkbox';
import MarkdownEditor from '../components/form/MarkdownEditor';
import { PaperIcon } from '../components/Icons';

export default {
    baseUrl: '/articles',
    icon: PaperIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Articles',
        create: 'Add a new article',
        update: 'Update %title%',
        delete: 'Delete %title%',
        vars: {
            '%title%': 'title'
        }
    },
    calls: {
        list: ArticleModel.list,
        create: ArticleModel.create,
        update: ArticleModel.edit,
        delete: ArticleModel.remove,
        read: ArticleModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'title',
                title: 'Title'
            }
        ],
        paginate: true,
        rowsPerPage: 20
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'title',
                    props: {
                        label: 'Title',
                        required: true
                    }
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'category',
                    props: {
                        label: 'Category',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'articleCategory'
                }
            ],
            [
                {
                    component: MarkdownEditor,
                    field: 'introduction',
                    props: {
                        label: 'Introduction'
                    }
                }
            ],
            [
                {
                    component: MarkdownEditor,
                    field: 'content',
                    props: {
                        label: 'Content'
                    }
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false
                },
                {
                    component: Checkbox,
                    field: 'homepage',
                    props: {
                        label: 'Displayed on homepage widget?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            articleCategory: ArticleCategoryModel.list
        }
    }
};
