import translateFieldsHelper from '../helpers/translateFieldsHelper';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';

const transform = season =>  season === null ? null : ({
    ...season,
    rankings: season.rankings ? season.rankings.map(i => translateFieldsHelper(i, ['ranking'])) : [],
    collectorItems: season.collectorItems ? season.collectorItems.map(i => translateFieldsHelper(i, ['type'])) : []
});

const reverseTransform = season => ({
    ...season,
    rankings: season.rankings ? season.rankings.map(i => removeExtraSubFieldsHelper(i, ['competition', 'ranking'])) : [],
    collectorItems: season.collectorItems ? season.collectorItems.map(i => removeExtraSubFieldsHelper(i, ['type'])) : []
});

export { transform, reverseTransform };
