import Checkbox from '../components/form/Checkbox';
import Input from '../components/form/Input';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import PictureInput from '../components/form/PictureInput';
import CompetitionModel from '../models/CompetitionModel';
import { TrophyAltIcon } from '../components/Icons';

export default {
    baseUrl: '/competitions',
    icon: TrophyAltIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Competitions',
        create: 'Add a new competition',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'name'
        }
    },
    calls: {
        list: CompetitionModel.list,
        create: CompetitionModel.create,
        update: CompetitionModel.edit,
        delete: CompetitionModel.remove,
        read: CompetitionModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'name',
                    props: {
                        label: 'Name'
                    }
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'childOf',
                    props: {
                        label: 'This competition is a child of',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'competition'
                },
                {
                    component: Input,
                    field: 'priority',
                    props: {
                        label: 'Display priority'
                    }
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'championship',
                    props: {
                        label: 'Is it a championship competition?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            competition: CompetitionModel.list
        }
    }
};
