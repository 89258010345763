import { isArray } from 'lodash';

export default (data, fields) => {
    if (data === null) {
        return null;
    }

    const out = { ...data };

    fields.forEach(f => {
        if (out[f]) {
            out[f] = isArray(out[f]) ? out[f].map(i => ({ id: i.id })) : { id: out[f].id };
        }
    });

    return out;
};
