import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default (props) => {
    const handleChange = e => props.onChange(e.target.checked);

    return (
        <FormControlLabel
            style={props.style ? props.style : {margin: '0.5rem'}}
            control={
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize={props.size ? props.size : 'medium'} />}
                    checkedIcon={<CheckBoxIcon fontSize={props.size ? props.size : 'medium'} />}
                    checked={props.value}
                    onChange={handleChange}
                    color="primary"
                />
            }
            label={props.label}
        />
    );
}
