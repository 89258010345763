import moment from 'moment';

const generateDefault = date => new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
}).formatToParts(date).reduce((acc, current) => ({ ...acc, [current.type]: current.value }), {});

const defaultDateTime = date => {
    const d = generateDefault(date);

    return `${d.month}/${d.day}/${d.year} ${d.hour}:${d.minute}`;
};

const defaultDate = date => {
    const d = generateDefault(date);

    return `${d.month}/${d.day}/${d.year}`;
};

const formDateTimeToApi = dateTime => {
    return dateTime ? moment(dateTime).format() : null;
}

const formDateToApi = date => date ? moment(date).format('YYYY-MM-DD') : null;

const apiDateToForm = date => date ? moment(date + 'T00:00:00Z') : null;

export { defaultDate, defaultDateTime, apiDateToForm, formDateToApi, formDateTimeToApi };
