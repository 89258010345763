import {apiDateToForm, formDateToApi} from '../helpers/dateHelper';

const transform = scorer => ({
    ...scorer,
    birthDate: apiDateToForm(scorer.birthDate)
});

const reverseTransform = scorer => ({
    ...scorer,
    birthDate: formDateToApi(scorer.birthDate)
});

export { transform, reverseTransform };
