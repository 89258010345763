import React from 'react';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';
import DatePicker from './DatePicker';

export default props => {
    return <CollectionItem onDelete={props.onDelete} className="autogrid has-gutter">
        <DatePicker
            label="Arrival date"
            value={props.value.from || null}
            onChange={v => props.onChange('from', v)}
        />
        <DatePicker
            label="Departure date"
            value={props.value.to || null}
            onChange={v => props.onChange('to', v)}
        />
        <AutocompleteSelect
            label="Club"
            options={props.clubOptions}
            optionLabel={o => o.shortName}
            value={props.value.club || null}
            variant="standard"
            onChange={v => props.onChange('club', v)}
        />
        <AutocompleteSelect
            label="Role"
            options={props.roleOptions}
            optionLabel={o => o.name}
            value={props.value.role || null}
            variant="standard"
            onChange={v => props.onChange('role', v)}
        />
    </CollectionItem>;
};
