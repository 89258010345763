import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

export default props => <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDateTimePicker
        format="DD/MM/YYYY HH:mm"
        {...props}
        onChange={props.onChange}
    />
</MuiPickersUtilsProvider>;
