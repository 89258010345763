import React, { useEffect, useState } from 'react';
import Collection from './Collection';
import CompetitionModel from '../../models/CompetitionModel';
import RankingModel from '../../models/RankingModel';
import SeasonRanking from './SeasonRanking';

export default props => {
    const [options, setOptions] = useState({ competitions: [], rankings: [] })

    useEffect(() => {
        Promise.all([
            CompetitionModel.list(),
            RankingModel.list()
        ]).then(([competitions, rankings]) => setOptions({ competitions, rankings }));
    }, []);

    return <Collection
        {...props}
        itemProps={{
            competitionOptions: options.competitions,
            rankingOptions: options.rankings
        }}
        itemTemplate={{
            ranking: null,
            competition: null,
            points: ''
        }}
        itemComponent={SeasonRanking}
    />;
};
