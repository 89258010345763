import RestModel from './RestModel';

const resourceName = 'media';

export default {
    create: resource => RestModel.create(resourceName, resource),

    edit: resource => RestModel.edit(resourceName, resource),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id),

    remove: id => RestModel.remove(resourceName, id),
};
