import React, { useEffect, useState } from 'react';
import Collection from './Collection';
import ClubModel from '../../models/ClubModel';
import PlayerTransferTypeModel from '../../models/PlayerTransferTypeModel';
import PlayerTransfer from './PlayerTransfer';

export default props => {
    const [options, setOptions] = useState({ types: [], clubs: [] })

    useEffect(() => {
        Promise.all([
            ClubModel.list(),
            PlayerTransferTypeModel.list()
        ]).then(([clubs, types]) => setOptions({ clubs, types }));
    }, []);

    return <Collection
        {...props}
        itemProps={{
            typeOptions: options.types,
            clubOptions: options.clubs
        }}
        itemTemplate={{
            arrival: null,
            price: '',
            club: null,
            type: null
        }}
        itemComponent={PlayerTransfer}
    />;
};
