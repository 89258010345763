import React from 'react';
import GameTimeInput from './GameTimeInput';
import Input from './Input';
import Checkbox from './Checkbox';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';

export default props => {
    return <CollectionItem onDelete={props.onDelete}>
        <GameTimeInput
            label="Goal minute"
            size="small"
            timeValue={props.value.time}
            extraTimeValue={props.value.extraTime}
            onTimeChange={v => props.onChange('time', v)}
            onExtraTimeChange={v => props.onChange('extraTime', v)}
        />
        <Checkbox
            style={{ margin: 0 }}
            size="small"
            label="Penalty?"
            value={props.value.penalty}
            onChange={v => props.onChange('penalty', v)}
        />
        <AutocompleteSelect
            style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}
            size="small"
            label="Assisted by..."
            options={props.playerOptions}
            optionLabel={p => `${p.firstName} ${p.lastName}`}
            value={props.value.assistedBy ? props.value.assistedBy : null}
            onChange={v => props.onChange('assistedBy', v)}
        />
        <Input
            style={{ margin: 0, width: '100%' }}
            size="small"
            label="Video link"
            value={props.value.videoLink}
            onChange={v => props.onChange('videoLink', v)}
        />
    </CollectionItem>;
};
