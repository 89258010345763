import RestModel from './RestModel';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';

const resourceName = 'competitions';

export default {
    create: resource => RestModel.create(resourceName, removeExtraSubFieldsHelper(resource, ['childOf'])),

    edit: resource => RestModel.edit(resourceName, removeExtraSubFieldsHelper(resource, ['childOf'])),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id),

    remove: id => RestModel.remove(resourceName, id),
};
