import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default (props) => {
    const handleChange = (e, val) => {
        props.onChange(val);
    };

    return (
        <Autocomplete
            style={props.style || {}}
            options={props.options}
            value={props.value}
            size={props.size ? props.size : 'medium'}
            multiple={!!props.multiple}
            getOptionLabel={props.optionLabel}
            renderInput={params => <TextField {...params} label={props.label} />}
            onChange={handleChange}
        />
    );
}
