import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardHeader
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SyncModel from '../models/SyncModel';

const useStyles = makeStyles(() => ({
    root: {
        width: '480px',
        margin: '1rem',
        maxWidth: '100%'
    },
    avatar: {
        backgroundColor: '#20bf6b'
    },
}));

export default function CreateNotification(props) {
    const classes = useStyles();

    const handleSeenClick = async () => {
        await SyncModel.markAsSeen(props.notification.id);

        props.onSeen();
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        { props.notification.instance.split(' ').map(p => p.substr(0, 1)).join('') }
                    </Avatar>
                }
                title={`Creation "${props.notification.label}"`}
                subheader={`by ${props.notification.instance}`}
            />
            <CardActions disableSpacing>
                <Button href={props.notification.link}>See page</Button>
                <Button color="primary" onClick={handleSeenClick}>OK</Button>
            </CardActions>
        </Card>
    );
}
