import Input from '../components/form/Input';
import { UiSettingsIcon } from '../components/Icons';
import ConfigModel from '../models/ConfigModel';
import PictureInput from '../components/form/PictureInput';
import ScalarAutocompleteSelect from '../components/form/ScalarAutocompleteSelect';
import TranslatableInput from '../components/form/TranslatableInput';
import Collection from '../components/form/Collection';
import ExtraMenuLink from '../components/form/ExtraMenuLink';
import Checkbox from '../components/form/Checkbox';
import Banner from '../components/form/Banner'

const widgetOptions = {
    season: 'Ongoing season',
    transfers: 'Transfer rumors',
    articles: 'Articles',
    birthdays: 'Birthdays'
};

const featuresOptions = {
    stats: 'Statistics',
    articles: 'Articles'
};

export default {
    baseUrl: '/config',
    icon: UiSettingsIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Config',
        update: 'Update config'
    },
    calls: {
        update: ConfigModel.edit,
        read: ConfigModel.read
    },
    list: {
        noList: true
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                },
                {
                    component: Input,
                    field: 'title',
                    props: {
                        label: 'Website title'
                    }
                },
                {
                    component: TranslatableInput,
                    field: 'catchPhrase',
                    props: {
                        label: 'Website catch phrase'
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'homeWidgets',
                    props: {
                        label: 'Home widgets',
                        options: Object.keys(widgetOptions),
                        optionLabel: o => widgetOptions[o],
                        multiple: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'activatedFeatures',
                    props: {
                        label: 'Activated features',
                        options: Object.keys(featuresOptions),
                        optionLabel: o => featuresOptions[o],
                        multiple: true
                    },
                    defaultValue: ['stats', 'articles']
                }
            ],
            [
                {
                    component: Collection,
                    field: 'extraMenuLinks',
                    props: {
                        label: 'Extra menu links',
                        itemComponent: ExtraMenuLink,
                        itemTemplate: {
                            label: [],
                            value: ''
                        },
                        inline: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: Input,
                    field: 'twitterAccount',
                    props: {
                        label: '@TwitterUsername',
                        inputProps: {
                            pattern: '@[a-zA-Z0-9_]+'
                        }
                    }
                },
                {
                    component: Input,
                    field: 'googleAnalyticsId',
                    props: {
                        label: 'Google Analytics ID'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'analyticsScript',
                    props: {
                        label: 'Analytics script',
                        hint: 'Leave empty if you are using Google Analytics',
                        multiline: true
                    }
                }
            ],
            [
                {
                    component: TranslatableInput,
                    field: 'legal',
                    props: {
                        label: 'Legal',
                        multiline: true
                    },
                    defaultValue: []
                },
                {
                    component: Checkbox,
                    field: 'menTeam',
                    props: {
                        label: 'Men team?'
                    },
                    defaultValue: true
                },
                {
                    component: Checkbox,
                    field: 'shouldReceiveNotifications',
                    props: {
                        label: 'Should receive notifications?'
                    },
                    defaultValue: true
                }
            ],
            [
                {
                    component: Banner,
                    field: 'banner',
                    defaultValue: {
                        content: '',
                        link: '',
                        linkText: ''
                    }
                }
            ]
        ]
    }
}
