import config from '../config/config.json';
import RestModel from './RestModel';
import translate from '../helpers/translateHelper';
import { reverseTransform, transform } from '../transformers/roleTransformer';

const resourceName = 'roles';

export default {
    create: resource => RestModel.create(resourceName, reverseTransform(resource)),

    edit: resource => RestModel.edit(resourceName, reverseTransform(resource)),

    list: () => RestModel.list(`${resourceName}/${config.defaultLocale}`).then(l => l.map(i => translate(i))),

    read: id => RestModel.read(resourceName, id).then(r => transform(r)),

    remove: id => RestModel.remove(resourceName, id)
};
