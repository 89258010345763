import TranslatableInput from '../components/form/TranslatableInput';
import RankingModel from '../models/RankingModel';
import Input from '../components/form/Input';
import { ChartLineIcon } from '../components/Icons';

export default {
    baseUrl: '/rankings',
    icon: ChartLineIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Rankings',
        create: 'Add a new ranking',
        update: 'Update ranking',
        delete: 'Delete ranking',
    },
    calls: {
        list: RankingModel.list,
        create: RankingModel.create,
        update: RankingModel.edit,
        delete: RankingModel.remove,
        read: RankingModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: TranslatableInput,
                    field: 'name',
                    props: {
                        label: 'Name',
                        multiline: false
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: Input,
                    field: 'priority',
                    props: {
                        label: 'Order'
                    }
                }
            ]
        ]
    }
}
