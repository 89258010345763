import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';
import translateFieldsHelper from '../helpers/translateFieldsHelper';

const transform = club => ({
    ...translateFieldsHelper(club, ['nationality']),
});

const reverseTransform = club => ({
    ...removeExtraSubFieldsHelper(club, ['nationality']),
    website: club.website === '' ? null : club.website
});

export { transform, reverseTransform };
