import React, { useEffect, useState } from 'react';
import Collection from './Collection';
import PlayerModel from '../../models/PlayerModel';
import GroupPlayerTypeModel from '../../models/GroupPlayerTypeModel';
import GameGroupPlayer from './GameGroupPlayer';

export default props => {
    const [options, setOptions] = useState({ types: [], players: [] })

    useEffect(() => {
        Promise.all([
            PlayerModel.list(),
            GroupPlayerTypeModel.list()
        ]).then(([players, types]) => setOptions({ players, types }));
    }, []);

    return <Collection
        {...props}
        itemProps={{
            typeOptions: options.types,
            playerOptions: options.players
        }}
        itemTemplate={{
            player: null,
            type: null
        }}
        itemComponent={GameGroupPlayer}
    />;
};
