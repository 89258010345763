import Input from '../components/form/Input';
import LinkModel from '../models/LinkModel';
import DatePicker from '../components/form/DatePicker';
import {defaultDate} from '../helpers/dateHelper';
import { LinkIcon } from '../components/Icons';

export default {
    baseUrl: '/referring-links',
    icon: LinkIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Referring links',
        create: 'Add a new referring link',
        update: 'Update %link%',
        delete: 'Delete %link%',
        vars: {
            '%link%': 'link'
        }
    },
    calls: {
        list: LinkModel.list,
        create: LinkModel.create,
        update: LinkModel.edit,
        delete: LinkModel.remove,
        read: LinkModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'websiteName',
                title: 'Website name'
            }
        ],
        paginate: true,
        rowsPerPage: 20
    },
    form: {
        blocks: [
            [
                {
                    component: DatePicker,
                    field: 'date',
                    props: {
                        label: 'Date'
                    },
                    defaultValue: defaultDate(new Date())
                },
                {
                    component: Input,
                    field: 'websiteName',
                    props: {
                        label: 'Website name'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'link',
                    props: {
                        label: 'Link'
                    }
                }
            ]
        ]
    }
};
