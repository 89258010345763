import { SpinnerAlt5Icon } from '../components/Icons';
import SeasonBoundingModel from '../models/SeasonBoundingModel';
import ScalarAutocompleteSelect from '../components/form/ScalarAutocompleteSelect';
import { range } from 'lodash';

export default {
    baseUrl: '/season-boundings',
    icon: SpinnerAlt5Icon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Season boundings',
        create: 'Add a new season bounding',
        update: 'Update %from%-%to%',
        delete: 'Delete %from%-%to%',
        vars: {
            '%from%': 'fromYear',
            '%to%': 'toYear'
        }
    },
    calls: {
        list: SeasonBoundingModel.list,
        create: SeasonBoundingModel.create,
        update: SeasonBoundingModel.edit,
        delete: SeasonBoundingModel.remove,
        read: SeasonBoundingModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'fromYear',
                title: 'From year'
            },
            {
                field: 'toYear',
                title: 'To year'
            }
        ]
    },
    form: {
        blocks: [
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'fromYear',
                    props: {
                        label: 'From year',
                        options: range(1800, 2100).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: "1800"
                },
                {
                    component: ScalarAutocompleteSelect,
                    field: 'toYear',
                    props: {
                        label: 'To year',
                        options: range(1800, 2100).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: (new Date()).getFullYear().toString()
                }
            ],
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'startMonth',
                    props: {
                        label: 'Month of the year the season starts',
                        options: range(1, 12).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: "7"
                },
                {
                    component: ScalarAutocompleteSelect,
                    field: 'startDay',
                    props: {
                        label: 'Day the season starts',
                        options: range(1, 31).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: "16"
                }
            ],
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'endMonth',
                    props: {
                        label: 'Month of the year the season ends (the year after)',
                        options: range(1, 12).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: "7"
                },
                {
                    component: ScalarAutocompleteSelect,
                    field: 'endDay',
                    props: {
                        label: 'Day the season ends',
                        options: range(1, 31).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: false
                    },
                    defaultValue: "15"
                }
            ]
        ]
    }
}
