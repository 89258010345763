import {isArray} from 'lodash';
import {defaultLocale} from '../config/config.json';

export default (toTranslate, field = 'name') => {

    if (!toTranslate) {
        return toTranslate;
    }

    if (isArray(toTranslate) && toTranslate.length > 0) {
        return toTranslate.map(i => ({
            ...i,
            [field]: i[field].find(n => n.locale === defaultLocale).translation
        }));
    }

    if (!toTranslate[field]) {
        return toTranslate;
    }

    return {
        ...toTranslate,
        [field]: toTranslate[field].find(n => n.locale === defaultLocale).translation
    };
}
