import React, { useContext, useEffect } from 'react';
import UserContext from '../contexts/UserContext';
import SecurityModel from '../models/SecurityModel';

const AuthWrapper = props => {
    const [user, setUser] = useContext(UserContext);

    const retrieveUserOrLogin = () => {
        if (window.location.pathname !== '/login') {
            if (user === null) {
                if (!localStorage.getItem('token')) {
                    window.location.href = '/login';
                } else {
                    SecurityModel.me().then(setUser);
                }
            }
        }
    }

    useEffect(retrieveUserOrLogin, []);

    return <>
        { props.children }
    </>;
};

export default AuthWrapper;
