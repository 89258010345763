import React, {useEffect, useState} from 'react';
import { range } from 'lodash';
import { polyfill } from 'mobile-drag-drop';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GamePlayer from './GamePlayer';
import PlayerModel from '../../models/PlayerModel';
import GamePositionModel from '../../models/GamePositionModel';
import GameModel from '../../models/GameModel';
import { formDateToApi } from '../../helpers/dateHelper';
import CopyPreviousButton from './CopyPreviousButton';
import CopyNextButton from './CopyNextButton';

export default props => {
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);
    const [players, setPlayers] = useState([]);
    const [positions, setPositions] = useState([]);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [draggedOverIndexAndPosition, setDraggedOverIndexAndPosition] = useState(null);

    useEffect(() => {
        PlayerModel.list().then(data => setPlayers(data));
        GamePositionModel.list().then(data => setPositions(data));
    }, []);

    useEffect(() => {
        GameModel.previous(formDateToApi(props.date)).then(data => setPrevious(data));
        GameModel.next(formDateToApi(props.date)).then(data => setNext(data));
    }, [props.date]);

    const handleAdd = (x, y) => {
        const newValue = [...props.value];

        newValue.push({
            player: null,
            position: positions.find(p => p.x === x && p.y === y),
            penaltyCards: [],
            time: 90,
            goals: [],
            captain: false
        });

        props.onChange(newValue);
    };

    const handleChange = (x, y, newPlayerValue) => {
        const newValue = [...props.value];

        newValue[newValue.findIndex(p => p.position.x === x && p.position.y === y)] = newPlayerValue;

        props.onChange(newValue);
    };

    const handleRemove = (x, y) => {
        const newValue = [...props.value];

        newValue.splice(newValue.findIndex(p => p.position && p.position.x === x && p.position.y === y), 1);

        props.onChange(newValue);
    };

    const handleSubAdd = () => {
        const newValue = [...props.value];

        newValue.push({
            player: null,
            position: positions.find(p => p.x === -1),
            penaltyCards: [],
            time: 0,
            goals: [],
            captain: false,
            replaces: null,
            replacesAt: ''
        });

        props.onChange(newValue);
    };

    const handleSubChange = (i, newPlayerValue) => {
        const newValue = [...props.value];

        newValue[i] = newPlayerValue;

        props.onChange(newValue);
    };

    const handleSubRemove = i => {
        const newValue = [...props.value];

        newValue.splice(i, 1);

        props.onChange(newValue);
    };

    const handleDragStart = playerIndex => setDraggedIndex(playerIndex);

    const handleDragEnd = () => {
        setDraggedIndex(null);
        setDraggedOverIndexAndPosition(null);
    }

    const handleDragOver = (playerIndex, x, y) => setDraggedOverIndexAndPosition({ playerIndex, x, y });

    const handleDragLeave = () => setDraggedOverIndexAndPosition(null);

    const handleDrop = (droppedOnX, droppedOnY, droppedOnPlayerIndex) => {
        setDraggedOverIndexAndPosition(null);

        if (null === draggedIndex || -1 === draggedIndex) {
            return;
        }

        const draggedPositionId = props.value[draggedIndex].position.id;
        const newValue = [...props.value];

        if (droppedOnX !== -1 && droppedOnY !== -1) {
            newValue[draggedIndex].position = positions.find(p => p.x === droppedOnX && p.y === droppedOnY);
        } else {
            newValue[draggedIndex].position = positions.find(p => p.id === props.value[droppedOnPlayerIndex].position.id);
        }

        if (droppedOnPlayerIndex !== -1) {
            const draggedPosition = positions.find(p => p.id === draggedPositionId);

            newValue[droppedOnPlayerIndex].position = draggedPosition;

            if (draggedPosition.x === -1 || droppedOnX === -1) {
                const draggedGameTime = newValue[draggedIndex].time;

                newValue[draggedIndex].time = newValue[droppedOnPlayerIndex].time;
                newValue[droppedOnPlayerIndex].time = draggedGameTime;
            }
        }

        props.onChange(newValue);
    };

    const handlePreviousCopyClick = () => {
        props.onChange(previous.players.map(p => ({
            player: p.player,
            time: p.position.x === -1 ? 0 : 90,
            penaltyCards: [],
            goals: [],
            captain: false,
            position: positions.find(po => po.id === p.position.id),
        })));
    }

    const handleNextCopyClick = () => {
        props.onChange(next.players.map(p => ({
            player: p.player,
            time: p.position.x === -1 ? 0 : 90,
            penaltyCards: [],
            goals: [],
            captain: false,
            position: positions.find(po => po.id === p.position.id),
            replaces: null,
            replacesAt: ''
        })));
    }

    const titular = props.value.filter(p => p.position && p.position.x !== -1);
    const openable = titular.length < 11;
    const substitutablePlayers = props.value.filter(p => !!p.player && (p.position.x !== -1 || !!p.replaces)).map(p => p.player);
    const captainable = !props.value.find(p => p.captain);

    polyfill({});

    return <div>
        {
            previous ? <CopyPreviousButton onClick={handlePreviousCopyClick} /> : null
        }
        {
            next ? <CopyNextButton onClick={handleNextCopyClick} /> : null
        }
        {
            range(6, 0, -1).map(y => <div key={y} className={'row grid-5-tiny-5'}>
                {range(1, 6).map(x => <div
                    key={x}
                    style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#e0efd9' }}
                >
                    { y > 1 || x === 3 ? (
                        <GamePlayer
                            x={x}
                            y={y}
                            player={props.value.find(p => p.position.x === x && p.position.y === y) || null}
                            playerIndex={props.value.findIndex(p => p.position.x === x && p.position.y === y)}
                            players={players}
                            substitutablePlayers={substitutablePlayers}
                            openable={openable}
                            captainable={captainable}
                            sub={false}
                            draggedOver={draggedOverIndexAndPosition !== null && draggedOverIndexAndPosition.x === x && draggedOverIndexAndPosition.y === y}
                            onAdd={handleAdd}
                            onChange={handleChange}
                            onRemove={handleRemove}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        />
                    ) : null}
                </div>)}
            </div>)
        }
        <div className="grid-5-tiny-5">
            { props.value.map((p, i) => (
                p.position.x === - 1 ? (
                    <div key={i} style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#d2d9e1' }}>
                        <GamePlayer
                            x={-1}
                            y={-1}
                            player={p}
                            playerIndex={i}
                            players={players}
                            substitutablePlayers={substitutablePlayers}
                            openable={true}
                            captainable={false}
                            sub={true}
                            draggedOver={draggedOverIndexAndPosition !== null && draggedOverIndexAndPosition.playerIndex !== -1 && draggedOverIndexAndPosition.playerIndex === i}
                            onChange={(x, y, v) => handleSubChange(i, v)}
                            onRemove={() => handleSubRemove(i)}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        />
                    </div>
                ) : null
            )) }
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d2d9e1' }}>
                <Fab size="small" color="primary" onClick={handleSubAdd}>
                    <AddIcon />
                </Fab>
            </div>
        </div>
    </div>;
};
