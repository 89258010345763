import RestModel from './RestModel';
import { transform, reverseTransform } from '../transformers/opponentScorerTransformer';
import { isNil, omitBy, map } from 'lodash';

const resourceName = 'opponent-scorers';

export default {
    create: resource => RestModel.create(resourceName, reverseTransform(resource)),

    edit: resource => RestModel.edit(resourceName, reverseTransform(resource)),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id).then(s => transform(s)),

    remove: id => RestModel.remove(resourceName, id),

    match: (firstName, lastName, birthDate) => {
        const relevantParams = omitBy({ firstName, lastName, birthDate }, v => isNil(v) || v === '');

        return RestModel.list(`${resourceName}/match?${map(relevantParams, (val, k) => `${k}=${val}`).join('&')}`);
    },
};
