import RestModel from './RestModel';
import config from '../config/config.json';
import translate from '../helpers/translateHelper';

const resourceName = 'rankings';

export default {
    create: resource => RestModel.create(resourceName, resource),

    edit: resource => RestModel.edit(resourceName, resource),

    list: () => RestModel.list(`${resourceName}/${config.defaultLocale}`).then(l => l.map(i => translate(i))),

    read: id => RestModel.read(resourceName, id),

    remove: id => RestModel.remove(resourceName, id)
};
