import React from 'react';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';
import Input from './Input';

export default props => {
    return <CollectionItem onDelete={props.onDelete} className="autogrid has-gutter">
        <AutocompleteSelect
            label="Ranking"
            options={props.rankingOptions}
            optionLabel={o => o.name}
            value={props.value.ranking || null}
            variant="standard"
            onChange={v => props.onChange('ranking', v)}
        />
        <AutocompleteSelect
            label="Competition"
            options={props.competitionOptions}
            optionLabel={o => o.name}
            value={props.value.competition || null}
            variant="standard"
            onChange={v => props.onChange('competition', v)}
        />
        <Input
            style={{ width: 'auto' }}
            label="Points"
            value={props.value.points || ''}
            onChange={v => props.onChange('points', v)}
        />
    </CollectionItem>;
};
