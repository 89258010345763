import translateFieldsHelper from '../helpers/translateFieldsHelper';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';
import { formDateTimeToApi } from '../helpers/dateHelper';

const transform = game => ({
    ...translateFieldsHelper(game, ['turn', 'rankingAfterGame']),
    collectorItems: game.collectorItems ? game.collectorItems.map(i => translateFieldsHelper(i, ['type'])) : []
});

const reverseTransform = game => ({
    ...removeExtraSubFieldsHelper(game, ['opponent', 'turn', 'competition', 'referee', 'rankingAfterGame', 'season', 'article']),
    date: formDateTimeToApi(game.date),
    score: game.score === '' ? null : game.score,
    opponentScore: game.opponentScore === '' ? null : game.opponentScore,
    psoScore: game.psoScore === '' ? null : game.psoScore,
    opponentPsoScore: game.opponentPsoScore === '' ? null : game.opponentPsoScore,
    crowd: game.crowd === '' ? null : game.crowd,
    players: game.players ? game.players.map(p => ({
        ...removeExtraSubFieldsHelper(p, ['player', 'replaces']),
        time: p.time === '' ? null : p.time,
        replacesAt: p.replacesAt === '' ? null : p.replacesAt,
        goals: p.goals ? p.goals.map(g => ({
            ...g,
            time: g.time === '' ? null : g.time,
            extraTime: g.extraTime === '' ? null : g.extraTime
        })) : []
    })) : [],
    opponentGoals: game.opponentGoals ? game.opponentGoals.map(g => ({
        ...removeExtraSubFieldsHelper(g, ['scorer']),
        time: g.time === '' ? null : g.time,
        extraTime: g.extraTime === '' ? null : g.extraTime
    })) : [],
    opponentOwnGoals: game.opponentOwnGoals ? game.opponentOwnGoals.map(g => ({
        ...removeExtraSubFieldsHelper(g, ['scorer']),
        time: g.time === '' ? null : g.time,
        extraTime: g.extraTime === '' ? null : g.extraTime
    })) : [],
    collectorItems: game.collectorItems ? game.collectorItems.map(i => removeExtraSubFieldsHelper(i, ['type'])) : [],
    archives: game.archives ? game.archives.map(i => removeExtraSubFieldsHelper(i, ['medium', 'picture'])) : []
});

export { transform, reverseTransform };
