import Checkbox from '../components/form/Checkbox';
import Input from '../components/form/Input';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import PictureInput from '../components/form/PictureInput';
import NationalityModel from '../models/NationalityModel';
import ClubModel from '../models/ClubModel';
import { get } from 'lodash';
import { JerseyIcon } from '../components/Icons';

export default {
    baseUrl: '/clubs',
    icon: JerseyIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Clubs',
        create: 'Add a new club',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'shortName'
        }
    },
    calls: {
        list: ClubModel.list,
        create: ClubModel.create,
        update: ClubModel.edit,
        delete: ClubModel.remove,
        read: ClubModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'shortName',
                title: 'Short name'
            },
            {
                field: 'name',
                title: 'Full name'
            }
        ],
        filters: [
            {
                title: 'Short name',
                filter: (item, value) => get(item, 'shortName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Full name',
                filter: (item, value) => get(item, 'name').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'name',
                    props: {
                        label: 'Full name',
                        required: true
                    }
                },
                {
                    component: Input,
                    field: 'shortName',
                    props: {
                        label: 'Short name',
                        required: true
                    }
                },
                {
                    component: Input,
                    field: 'acronym',
                    props: {
                        label: 'Acronym',
                        required: true
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'town',
                    props: {
                        label: 'Town',
                        required: true
                    }
                },
                {
                    component: AutocompleteSelect,
                    field: 'nationality',
                    props: {
                        label: 'Country',
                        optionLabel: o => o ? o.name : '',
                        required: true
                    },
                    defaultValue: null,
                    optionsProvider: 'nationality',
                },
                {
                    component: Input,
                    field: 'established',
                    props: {
                        label: 'Established (year)'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'stadium',
                    props: {
                        label: 'Stadium name'
                    }
                },
                {
                    component: Input,
                    field: 'stadiumSeats',
                    props: {
                        label: 'Number of seats'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'website',
                    props: {
                        label: 'Website URL'
                    }
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            nationality: NationalityModel.list
        }
    }
};
