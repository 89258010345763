import { get } from 'lodash';
import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import UserModel from '../models/UserModel';
import AutocompleteSelectCollection from '../components/form/AutocompleteSelectCollection';
import { UserIcon } from '../components/Icons';

export default {
    baseUrl: '/users',
    icon: UserIcon,
    roles: ['ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Users',
        create: 'Add a new user',
        update: 'Update %first_name% %last_name%',
        delete: 'Delete %first_name% %last_name%',
        vars: {
            '%first_name%': 'firstName',
            '%last_name%': 'lastName'
        }
    },
    calls: {
        list: UserModel.list,
        create: UserModel.create,
        update: UserModel.edit,
        delete: UserModel.remove,
        read: UserModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'username',
                title: 'Username'
            }
        ],
        filters: [
            {
                title: 'Username',
                filter: (item, value) => get(item, 'username').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name'
                    }
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name'
                    }
                },
                {
                    component: Input,
                    field: 'username',
                    props: {
                        label: 'Username'
                    }
                },
                {
                    component: Input,
                    field: 'email',
                    props: {
                        label: 'Email'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'plainPassword',
                    props: {
                        label: 'Password',
                        type: 'password'
                    }
                },
                {
                    component: Input,
                    field: 'description',
                    props: {
                        multiline: true,
                        label: 'Description'
                    }
                }
            ],
            [
                {
                    component: AutocompleteSelectCollection,
                    field: 'roles',
                    props: {
                        label: 'Roles',
                        options: [
                            'ROLE_BETTOR',
                            'ROLE_ARCHIVER',
                            'ROLE_ADMIN',
                            'ROLE_SUPER_ADMIN',
                            'ROLE_TRANSLATOR'
                        ]
                    },
                    defaultValue: []
                }
            ]
        ]
    }
}
