import React, { useState } from 'react';
import { Badge, MenuItem, Select, TextField } from '@material-ui/core';
import { locales, defaultLocale } from '../../config/config.json';

export default props => {
    const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

    const handleLocaleChange = e => {
        setSelectedLocale(e.target.value);
    };

    const handleInputChange = e => {
        const newValue = [...props.value];
        const translationIndex = newValue.findIndex(i => i.locale === selectedLocale);

        if (translationIndex === -1) {
            newValue.push({
                locale: selectedLocale,
                translation: e.target.value
            });
        } else {
            newValue[translationIndex].translation = e.target.value;
        }

        props.onChange(newValue);
    };

    const inputValue = () => {
        const found = props.value.find(i => i.locale === selectedLocale);

        return found ? found.translation : '';
    };

    const hasMissingTranslations = () => {
        const filledTranslationsLength = props.value.map(v => v.translation).filter(v => v !== null && v !== '').length;

        return filledTranslationsLength > 0 && filledTranslationsLength < locales.length;
    };

    return <div style={{ width: '100%', margin: '0.5rem', display: props.multiline ? 'block' : 'flex' }}>
        <TextField
            style={{ width: '100%' }}
            {...props}
            value={inputValue()}
            onChange={handleInputChange}
        />
        <Badge
            color="error"
            variant="dot"
            invisible={!hasMissingTranslations()}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Select value={selectedLocale} onChange={handleLocaleChange}>
                { locales.map((l, i) => <MenuItem value={l} key={i}>{l.toUpperCase()}</MenuItem>) }
            </Select>
        </Badge>
    </div>;
}
