import React from 'react';
import GameTimeInput from './GameTimeInput';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';
import Checkbox from './Checkbox';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import Input from './Input';

const filter = createFilterOptions();

export default props => {
    return <CollectionItem onDelete={props.onDelete}>
        <AutocompleteSelect
            label="Scorer"
            options={props.parentProps.options}
            optionLabel={s => `${s.firstName} ${s.lastName} ${ s.birthDate ? '(' + moment(s.birthDate).format('DD/MM/YYYY') + ')' : '' }`}
            value={props.value.scorer ? props.value.scorer : null}
            variant="standard"
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push({
                        id: 'new',
                        firstName: params.inputValue,
                        lastName: 'missing? Add it?'
                    });
                }

                return filtered;
            }}
            required={true}
            onChange={v => props.onChange('scorer', v)}
            onRequireAdd={props.onRequireScorerAdd}
        />
        <GameTimeInput
            label="Goal minute"
            timeValue={props.value.time}
            extraTimeValue={props.value.extraTime}
            onTimeChange={v => props.onChange('time', v)}
            onExtraTimeChange={v => props.onChange('extraTime', v)}
        />
        <Checkbox label="Penalty?" value={props.value.penalty} onChange={v => props.onChange('penalty', v)} />
        <Checkbox label="Own goal?" value={props.value.ownGoal} onChange={v => props.onChange('ownGoal', v)} />
        <Input
            style={{ margin: 0, width: '100%' }}
            size="small"
            label="Video link"
            value={props.value.videoLink}
            onChange={v => props.onChange('videoLink', v)}
        />
    </CollectionItem>;
};
