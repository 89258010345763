import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default props => <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
        format="DD/MM/YYYY"
        minDate='01/01/1800'
        {...props}
        onChange={props.onChange}
    />
</MuiPickersUtilsProvider>;
