import RestModel from './RestModel';
import { transform, reverseTransform } from '../transformers/gameTransformer';

const resourceName = 'games';

export default {
    create: resource => RestModel.create(resourceName, reverseTransform(resource)),

    edit: resource => RestModel.edit(resourceName, reverseTransform(resource)),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id).then(g => transform(g)),

    remove: id => RestModel.remove(resourceName, id),

    previous: date => RestModel.read(resourceName, 'previous/' + date),

    next: date => RestModel.read(resourceName, 'next/' + date),

    guessStadium: (date, opponentId, isHome) => RestModel.read(resourceName, `guess-stadium/${date}/${opponentId}/${isHome ? '1' : '0'}`)
};
