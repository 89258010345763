import React from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { Typography } from '@material-ui/core';

const mdParser = new MarkdownIt();

export default props => <div>
    <Typography variant="caption">{ props.label }</Typography>
    <MdEditor
        value={props.value}
        onChange={({ text }) => props.onChange(text)}
        renderHTML={text => mdParser.render(text)}
    />
</div>
