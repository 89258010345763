import React from 'react';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';
import DatePicker from './DatePicker';
import Input from './Input';

export default props => {
    return <CollectionItem onDelete={props.onDelete} className="autogrid has-gutter">
        <DatePicker
            label="Arrival date"
            value={props.value.arrival || null}
            onChange={v => props.onChange('arrival', v)}
        />
        <Input
            style={{ width: 'auto' }}
            label="Price"
            value={props.value.price || ''}
            onChange={v => props.onChange('price', v)}
        />
        <AutocompleteSelect
            label="Club"
            options={props.clubOptions}
            optionLabel={o => o.shortName}
            value={props.value.club || null}
            variant="standard"
            onChange={v => props.onChange('club', v)}
        />
        <AutocompleteSelect
            label="Type"
            options={props.typeOptions}
            optionLabel={o => o.name}
            value={props.value.type || null}
            variant="standard"
            onChange={v => props.onChange('type', v)}
        />
    </CollectionItem>;
};
