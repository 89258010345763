import TranslatableInput from '../components/form/TranslatableInput';
import GroupPlayerTypeModel from '../models/GroupPlayerTypeModel';
import { AddressBookIcon } from '../components/Icons';

export default {
    baseUrl: '/group-player-types',
    icon: AddressBookIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Group player types',
        create: 'Add a new group player type',
        update: 'Update group player type',
        delete: 'Delete group player type',
    },
    calls: {
        list: GroupPlayerTypeModel.list,
        create: GroupPlayerTypeModel.create,
        update: GroupPlayerTypeModel.edit,
        delete: GroupPlayerTypeModel.remove,
        read: GroupPlayerTypeModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: TranslatableInput,
                    field: 'name',
                    props: {
                        label: 'Name',
                        multiline: false
                    },
                    defaultValue: []
                }
            ]
        ]
    }
}
