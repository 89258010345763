import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureModel from '../../models/PictureModel';
import './PictureInput.css';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id:  (new Date().getTime() + Math.floor((Math.random()*10000)+1)).toString(16),
            placeholderPicture: this.props.placeholderPicture || null,
            uploading: false
        };
    }

    componentDidUpdate() {
        if (this.props.placeholderPicture !== undefined && this.props.placeholderPicture !== this.state.placeholderPicture) {
            this.setState({ placeholderPicture: this.props.placeholderPicture });
        }
    }

    handleChange = (event) => {
        const input = event.target;

        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = e => this.setState({ placeholderPicture: e.target.result });
            reader.readAsDataURL(input.files[0]);
            this.setState({
                placeholderPicture: null,
                uploading: true
            });

            PictureModel
                .create(input.files[0])
                .then(({ id, displayPath }) => {
                    this.setState({
                        file: input.files[0],
                        uploading: false,
                        placeholderPicture: displayPath
                    });
                    this.props.onChange({ id });
                });
        } else {
            this.setState({ file: null });
        }
    };

    render() {
        return <div className="PictureInput" style={{width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
            <input
                id={this.state.id}
                style={{
                    width: '0.1px',
                    height: '0.1px',
                }}
                type="file"
                name="pictureFile"
                onChange={this.handleChange}
            />
            <label
                htmlFor={this.state.id}
            >
                <Avatar
                    src={!this.state.uploading ? this.state.placeholderPicture : null}
                    style={{width: '5rem', height: '5rem'}}
                >
                    { this.state.uploading ? <CircularProgress color="inherit" /> : this.props.label }
                </Avatar>
            </label>
        </div>;
    }
}
