import { apiDateToForm, formDateToApi } from '../helpers/dateHelper';
import translateFieldsHelper from '../helpers/translateFieldsHelper';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';
import { values } from 'lodash';

const transform = player => {
    return ({
        ...translateFieldsHelper(player, ['birthCountry', 'nationalities', 'position', 'secondaryPositions']),
        birthDate: apiDateToForm(player.birthDate),
        deathDate: apiDateToForm(player.deathDate),
        transfers: player.transfers ? player.transfers.map(t => ({
            ...translateFieldsHelper(t, ['type']),
            arrival: apiDateToForm(t.arrival)
        })) : [],
        selections: values(player.selections),
        honours: values(player.honours)
    })
};

const reverseTransform = player => ({
    ...removeExtraSubFieldsHelper(player, ['birthCountry', 'nationalities', 'position', 'secondaryPositions', 'article']),
    birthDate: formDateToApi(player.birthDate),
    deathDate: formDateToApi(player.deathDate),
    contract: player.contract === '' ? null : player.contract,
    size: player.size === '' ? null : player.size,
    weight: player.weight === '' ? null : player.weight,
    transfers: player.transfers ? player.transfers.map(t => ({
        ...removeExtraSubFieldsHelper(t, ['type', 'club']),
        price: t.price === '' ? null : t.price,
        arrival: t.arrival ? formDateToApi(t.arrival) : null
    })) : []
});

export { transform, reverseTransform };
