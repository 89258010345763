import PictureInput from '../components/form/PictureInput';
import Checkbox from '../components/form/Checkbox';
import HomeBackgroundModel from '../models/HomeBackgroundModel';
import PictureList from '../components/PictureList';
import { PictureIcon } from '../components/Icons';

export default {
    baseUrl: '/home-backgrounds',
    icon: PictureIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Home backgrounds',
        create: 'Add a new home background',
        update: 'Update background',
        delete: 'Delete background'
    },
    calls: {
        list: HomeBackgroundModel.list,
        create: HomeBackgroundModel.create,
        update: HomeBackgroundModel.edit,
        delete: HomeBackgroundModel.remove,
        read: HomeBackgroundModel.read
    },
    list: {
        component: PictureList,
        displayedColumns: [],
        paginate: true,
        rowsPerPage: 20
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'forMobile',
                    props: {
                        label: 'For mobile?'
                    },
                    defaultValue: false
                }
            ]
        ]
    }
};
