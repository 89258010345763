import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import PartnerModel from '../models/PartnerModel';
import { HoldingHandsIcon } from '../components/Icons';

export default {
    baseUrl: '/partners',
    icon: HoldingHandsIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Partners',
        create: 'Add a new partner',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'websiteName'
        }
    },
    calls: {
        list: PartnerModel.list,
        create: PartnerModel.create,
        update: PartnerModel.edit,
        delete: PartnerModel.remove,
        read: PartnerModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'websiteName',
                title: 'Partner name'
            }
        ],
        paginate: false

    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'link',
                    props: {
                        label: 'Link'
                    }
                },
                {
                    component: Input,
                    field: 'websiteName',
                    props: {
                        label: 'Partner name'
                    }
                }
            ]
        ]
    }
};
