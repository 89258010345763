import React from 'react';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';
import PictureInput from './PictureInput';

export default props => {
    return <CollectionItem onDelete={props.onDelete}>
        <PictureInput
            label="Picture"
            placeholderPicture={props.value.picture ? props.value.picture.displayPath : ''}
            value={props.value.picture ? props.value.picture : null}
            onChange={v => props.onChange('picture', v)}
        />
        <AutocompleteSelect
            label="Medium"
            options={props.parentProps.options}
            optionLabel={t => t.name}
            value={props.value.medium ? props.value.medium : null}
            variant="standard"
            onChange={v => props.onChange('medium', v)}
        />
    </CollectionItem>;
};
