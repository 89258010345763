import Input from '../components/form/Input';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import SocialNetworkLinkModel from '../models/SocialNetworkLinkModel';
import SocialNetworkModel from '../models/SocialNetworkModel';
import { UiSocialLinkIcon } from '../components/Icons';

export default {
    baseUrl: '/social-network-links',
    icon: UiSocialLinkIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Social network links',
        create: 'Add a new social network link',
        update: 'Update %link%',
        delete: 'Delete %link%',
        vars: {
            '%link%': 'link',
        }
    },
    calls: {
        list: SocialNetworkLinkModel.list,
        create: SocialNetworkLinkModel.create,
        update: SocialNetworkLinkModel.edit,
        delete: SocialNetworkLinkModel.remove,
        read: SocialNetworkLinkModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'link',
                title: 'Link'
            }
        ],
        paginate: false

    },
    form: {
        blocks: [
            [
                {
                    component: AutocompleteSelect,
                    field: 'socialNetwork',
                    props: {
                        label: 'Network',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'socialNetwork'
                },
                {
                    component: Input,
                    field: 'link',
                    props: {
                        label: 'Link'
                    }
                }
            ]
        ],
        providers: {
            socialNetwork: SocialNetworkModel.list
        }
    }
};
