import React, { useEffect, useState } from 'react';
import GameModel from '../../models/GameModel';
import Input from './Input';
import { formDateToApi } from '../../helpers/dateHelper';

export default props => {
    const { date, opponentId, isHome, value, onChange } = props;
    const [typed, setTyped] = useState(false);
    const [externalDate, setExternalDate] = useState(date);
    const [externalOpponentId, setExternalOpponentId] = useState(opponentId);
    const [externalIsHome, setExternalIsHome] = useState(isHome);

    useEffect(() => setExternalDate(date), [date]);

    useEffect(() => setExternalOpponentId(opponentId), [opponentId]);

    useEffect(() => setExternalIsHome(isHome), [isHome]);

    const handleChange = (value) => {
        setTyped(true);
        onChange(value);
    };

    useEffect(() => {
        const changeStadium = async () => {
            if (isHome === externalIsHome && date === externalDate && opponentId === externalOpponentId) {
                return;
            }

            if (!typed && opponentId && !value) {
                const { stadium } = await GameModel.guessStadium(formDateToApi(date), opponentId, isHome);

                onChange(stadium);
            }
        }

        changeStadium();
    }, [opponentId, externalOpponentId, date, externalDate, isHome, externalIsHome, onChange, value, typed]);

    return <Input value={value} onChange={ handleChange } />;
};
