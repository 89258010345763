import translate from './translateHelper';

export default (object, fields, translationField = 'name') => {
    const newObject = {...object};

    for (let i = 0 ; i < fields.length ; i++) {
        newObject[fields[i]] = translate(newObject[fields[i]], translationField);
    }

    return newObject;
};
