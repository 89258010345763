import React from 'react';
import TextField from '@material-ui/core/TextField';
import {InputAdornment} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

export default props => <TextField
    style={{ width: '100%', margin: '0.5rem' }}
    {...props}
    InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <TimerIcon />
            </InputAdornment>
        ),
    }}
    onChange={e => props.onChange(e.target.value)}
/>;

