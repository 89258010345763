import React, { useState, useEffect } from 'react';
import OpponentScorerModel from '../../models/OpponentScorerModel';
import { formDateToApi } from '../../helpers/dateHelper';
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import moment from 'moment';

export default props => {
    const [ players, setPlayers ] = useState([]);

    const checkMatchingPlayer = async function () {
        if (props.value) {
            return;
        }

        try {
            const apiDate = formDateToApi(props.birthDate);
            const matching = await OpponentScorerModel.match(props.firstName, props.lastName, apiDate );

            if (matching.length === 0) {
                return;
            }

            if (matching.length === 1 && matching[0].birthdate === apiDate) {
                props.onChange(matching[0].id);
                return;
            }

            setPlayers(matching);
        } catch (e) {
        }
    };

    const applyEffect = () => { checkMatchingPlayer(); };

    useEffect(applyEffect, [props.firstName, props.lastName, props.birthDate]);

    if (!props.value && players.length > 0) {
        return <Dialog open={true} onClose={() => setPlayers([])}>
            <DialogTitle>Correspondence found with players from the registry</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    In the registry of potential opponent scorers, { players.length } player(s) have been found that
                    may correspond to the one you're creating. Click on the accurate one to link them.
                </DialogContentText>

                { players.map((p, i) => (
                    <Button color="primary" key={i} onClick={e => { e.preventDefault(); setPlayers([]); props.onChange(p.id); }}>
                        { p.firstname } { p.lastname } { p.birthdate ? moment(p.birthdate).format('DD/MM/YYYY') : null }
                    </Button>
                )) }
            </DialogContent>
        </Dialog>;
    }

    return <></>
};
