import { apiDateToForm, formDateToApi } from '../helpers/dateHelper';
import translateFieldsHelper from '../helpers/translateFieldsHelper';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';

const transform = leader => ({
    ...translateFieldsHelper(leader, ['birthCountry', 'nationalities']),
    birthDate: apiDateToForm(leader.birthDate),
    deathDate: apiDateToForm(leader.deathDate),
    career: leader.career ? leader.career.map(r => ({
        ...translateFieldsHelper(r, ['role']),
        from: apiDateToForm(r.from),
        to: apiDateToForm(r.to)
    })) : []
});

const reverseTransform = leader => ({
    ...removeExtraSubFieldsHelper(leader, ['birthCountry', 'nationalities', 'article']),
    birthDate: formDateToApi(leader.birthDate),
    deathDate: formDateToApi(leader.deathDate),
    career: leader.career ? leader.career.map(r => ({
        ...removeExtraSubFieldsHelper(r, ['role', 'club']),
        from: formDateToApi(r.from),
        to: formDateToApi(r.to)
    })) : [],
    contract: leader.contract === '' ? null : leader.contract
});

export { transform, reverseTransform };
