import React from 'react';
import { Fab, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CollectionItem from './CollectionItem';
import AutocompleteSelect from './AutocompleteSelect';

export default props => {
    const handleDelete = i => {
        const newValue = [...props.value];

        newValue.splice(i, 1);

        props.onChange(newValue);
    };

    const handleChange = (i, value) => {
        const newValue = [...props.value];

        newValue[i] = value;

        props.onChange(newValue);
    };

    const handleAdd = () => {
        props.onChange([...props.value, '']);
    };

    return <Paper
        elevation={1}
        className={ props.inline ? 'autogrid has-gutter' : '' }
        style={{
            width: '100%',
            padding: '1rem',
            boxSizing: 'border-box',
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)',
            marginTop: '1rem'
        }}
    >
        <Typography variant="caption">{ props.label }</Typography>
        { props.value.map((item, i) => (
            <Paper
                key={i}
                elevation={1}
                style={{
                    width: '100%',
                    padding: '1rem',
                    marginBottom: '0.5rem',
                    boxSizing: 'border-box',
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)'
                }}
            >
                <CollectionItem onDelete={() => handleDelete(i)}>
                    <AutocompleteSelect options={props.options} value={item} onChange={v => handleChange(i, v)} />
                </CollectionItem>
            </Paper>
        )) }
        <div style={{ marginTop: '1rem' }}>
            <Fab size="small" color="primary" aria-label="add" onClick={handleAdd}>
                <AddIcon />
            </Fab>
        </div>
    </Paper>;
};
