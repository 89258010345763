import { ChartArrowAxisIcon } from '../components/Icons'
import ChampionshipOutcomeModel from '../models/ChampionshipOutcomeModel'
import SeasonModel from '../models/SeasonModel'
import RankingModel from '../models/RankingModel'
import CompetitionModel from '../models/CompetitionModel'
import AutocompleteSelect from '../components/form/AutocompleteSelect'
import Checkbox from '../components/form/Checkbox'
import ColorPicker from '../components/form/ColorPicker'

export default {
    baseUrl: '/championship-outcomes',
    icon: ChartArrowAxisIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Championship outcomes',
        create: 'Add a new championship outcome',
        update: 'Update championship outcome',
        delete: 'Delete championship outcome'
    },
    calls: {
        list: ChampionshipOutcomeModel.list,
        create: ChampionshipOutcomeModel.create,
        update: ChampionshipOutcomeModel.edit,
        delete: ChampionshipOutcomeModel.remove,
        read: ChampionshipOutcomeModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'competition.name',
                title: 'Competition'
            },
            {
                field: 'fromSeason.season',
                title: 'Since season'
            },
            {
                field: 'toSeason.season',
                title: 'Until season'
            },
            {
                field: 'displayedRankings',
                title: 'Rankings'
            },
            {
                field: 'leadsToCompetition.name',
                title: 'Leads to'
            }
        ]
    },
    form: {
        blocks: [
            [
                {
                    component: AutocompleteSelect,
                    field: 'competition',
                    props: {
                        label: 'Championship competition',
                        optionLabel: c => c ? c.name : '',
                        required: true
                    },
                    defaultValue: null,
                    optionsProvider: 'competition'
                },
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'fromSeason',
                    props: {
                        label: 'Since season',
                        optionLabel: s => s ? s.season : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'season'
                },
                {
                    component: AutocompleteSelect,
                    field: 'toSeason',
                    props: {
                        label: 'Until season',
                        optionLabel: s => s ? s.season : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'season'
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'rankings',
                    props: {
                        label: 'Final rankings',
                        optionLabel: r => r.name,
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'ranking'
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'leadsToCompetition',
                    props: {
                        label: 'Leads to competition',
                        optionLabel: c => c ? c.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'competition'
                },
                {
                    component: Checkbox,
                    field: 'playOff',
                    props: {
                        label: 'Playoff?'
                    },
                    defaultValue: false
                }
            ],
            [
                {
                    component: ColorPicker,
                    field: 'color',
                    props: {
                        colors: [
                            '#2ecc71',
                            '#3498db',
                            '#9b59b6',
                            '#f1c40f',
                            '#e67e22',
                            '#e74c3c'
                        ]
                    },
                    defaultValue: '#2ecc71'
                }
            ]
        ],
        providers: {
            season: SeasonModel.list,
            ranking: RankingModel.list,
            competition: CompetitionModel.list,
        }
    },
}
