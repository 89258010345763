import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/UserContext';
import SyncModel from '../models/SyncModel';
import UpdateNotification from './UpdateNotification';
import { makeStyles } from '@material-ui/core/styles';
import CreateNotification from './CreateNotification';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

export default function NotificationsWrapper() {
    const [user] = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);
    const classes = useStyles();

    const loadNotifications = () => {
        if (!user) {
            return;
        }

        SyncModel.list().then(setNotifications);
    };

    useEffect(loadNotifications, [user]);

    const renderNotification = (key, n) => {
        if (n.old || n.new) {
            return <UpdateNotification key={key} notification={n} onSeen={loadNotifications} />
        }

        return <CreateNotification key={key} notification={n} onSeen={loadNotifications} />
    }

    return (
        <div className={classes.root}>
            { notifications.map((n, i) => renderNotification(i, n)) }
        </div>
    );
}
