import React, { useEffect, useState } from 'react';
import Collection from './Collection';
import ClubModel from '../../models/ClubModel';
import RoleModel from '../../models/RoleModel';
import LeaderCareerRole from './LeaderCareerRole';

export default props => {
    const [options, setOptions] = useState({ roles: [], clubs: [] })

    useEffect(() => {
        Promise.all([
            ClubModel.list(),
            RoleModel.list()
        ]).then(([clubs, roles]) => setOptions({ clubs, roles }));
    }, []);

    return <Collection
        {...props}
        itemProps={{
            roleOptions: options.roles,
            clubOptions: options.clubs
        }}
        itemTemplate={{
            from: null,
            to: null,
            club: null,
            role: null
        }}
        itemComponent={LeaderCareerRole}
    />;
};
