import TranslatableInput from '../components/form/TranslatableInput';
import TurnModel from '../models/TurnModel';
import Input from '../components/form/Input';
import { ChartFlowIcon } from '../components/Icons';

export default {
    baseUrl: '/turns',
    icon: ChartFlowIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Turns',
        create: 'Add a new turn',
        update: 'Update turn',
        delete: 'Delete turn',
    },
    calls: {
        list: TurnModel.list,
        create: TurnModel.create,
        update: TurnModel.edit,
        delete: TurnModel.remove,
        read: TurnModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: TranslatableInput,
                    field: 'name',
                    props: {
                        label: 'Name',
                        multiline: false
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: Input,
                    field: 'championshipOrder',
                    props: {
                        label: 'Order'
                    }
                }
            ]
        ]
    }
}
