import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Avatar, Fab, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default (props) => {
    const classes = useStyles();

    return <>
        <div className="grid-4-md-2">
            {props.rows.map((row, i) => (
                <div key={i} style={{ padding: '1rem' }}>
                    <div style={{ padding: '1rem', display: 'flex', justifyContent: 'center' }}>
                        <Avatar src={row.picture.displayPath} className={classes.large} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Fab color="default" onClick={() => { props.onEditClick(row.id) }} className={classes.small}>
                            <EditIcon />
                        </Fab>
                        <Fab color="secondary" onClick={() => { props.onDeleteClick(row.id) }} className={classes.small}>
                            <DeleteIcon />
                        </Fab>
                    </div>
                </div>
            ))}
        </div>
        {props.paginate && props.rows.length > 0 ? (
            <TablePagination
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                rowsPerPageOptions={[props.rowsPerPage]}
                page={props.page}
                onChangePage={props.onChangePage}
            />
        ) : null}
    </>;
};
