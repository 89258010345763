import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import SyncModel from '../models/SyncModel';
import UpdateNotificationDiff from './UpdateNotificationDiff';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '480px',
        margin: '1rem',
        maxWidth: '100%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expanded: {
        backgroundColor: '#EEEEEE'
    },
    avatar: {
        backgroundColor: '#f0932b'
    },
}));

export default function UpdateNotification(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleSeenClick = async () => {
        await SyncModel.markAsSeen(props.notification.id);

        props.onSeen();
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        { props.notification.instance.split(' ').map(p => p.substr(0, 1)).join('') }
                    </Avatar>
                }
                title={`Updates on "${props.notification.label}"`}
                subheader={`by ${props.notification.instance}`}
            />
            <CardActions disableSpacing>
                <Button href={props.notification.link}>See page</Button>
                <Button color="primary" onClick={handleSeenClick}>OK</Button>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse className={classes.expanded} in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="h5">Old values</Typography>
                    <Typography paragraph>
                        { Object.keys(props.notification.old).map(key => <UpdateNotificationDiff key={key} label={key} diff={props.notification.old[key]} />) }
                    </Typography>
                    <Typography variant="h5">New values</Typography>
                    <Typography paragraph>
                        { Object.keys(props.notification.new).map(key => <UpdateNotificationDiff key={key} label={key} diff={props.notification.new[key]} />) }
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}
