import React from 'react';
import { Button } from '@material-ui/core';

export default props => (
    <Button
        style={{ color: '#EEEEEE', backgroundColor: props.value ? '#5cb85c' : '#d9534f' }}
        onClick={() => props.onChange(!props.value)}
    >
        {props.value ? 'Arrival' : 'Departure'}
    </Button>
);
