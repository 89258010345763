import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

export default (props) => {
    const handleChange = color => props.onChange(color);

    return (
        <ButtonGroup>
            { props.colors.map((color, i) => (
                <Button
                    key={i}
                    variant="contained"
                    style={{ backgroundColor: color, color: '#FFFFFF' }}
                    disabled={props.value === color}
                    onClick={() => handleChange(color)}
                >
                    { props.value === color ? '✔' : '' }
                </Button>
            )) }
        </ButtonGroup>
    );
}
