import React from 'react';
import { sentenceCase } from 'change-case';

export default function UpdateNotificationDiff(props) {
    const renderValue = (diff) => {
        if (diff.type === 'picture') {
            return <img src={diff.value} style={{ width: '64px' }} alt="" />
        }

        return <>{ diff.value }</>
    }

    return (
        <>
            <strong>{ sentenceCase(props.label) }: </strong>
            { renderValue(props.diff) }
        </>
    );
}
