import RestModel from './RestModel';
import { transform, reverseTransform } from '../transformers/championshipOutcomeTransformer';

const resourceName = 'championship-outcomes';

export default {
    create: resource => RestModel.create(resourceName, reverseTransform(resource)),

    edit: resource => RestModel.edit(resourceName, reverseTransform(resource)),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id).then(transform),

    remove: id => RestModel.remove(resourceName, id)
};
