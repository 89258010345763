import PictureInput from '../components/form/PictureInput';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import CardModel from '../models/CardModel';
import ArchiveModel from '../models/ArchiveModel';
import PictureList from '../components/PictureList';
import { NewspaperIcon } from '../components/Icons';
import MediumModel from '../models/MediumModel';

export default {
    baseUrl: '/archives',
    icon: NewspaperIcon,
    roles: ['ROLE_ARCHIVER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Archives',
        create: 'Add a new archive',
        update: 'Update archive',
        delete: 'Delete archive'
    },
    calls: {
        list: ArchiveModel.list,
        create: ArchiveModel.create,
        update: ArchiveModel.edit,
        delete: ArchiveModel.remove,
        read: ArchiveModel.read
    },
    list: {
        component: PictureList,
        displayedColumns: [],
        filters: [
            {
                title: 'Name',
                filter: (item, value) => item.cards.filter(c => {
                    const discr = c.shortName ? c.shortName : `${c.firstName} ${c.lastName}`;

                    return discr.toLowerCase().indexOf(value.toLowerCase()) !== -1
                }).length > 0
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'cards',
                    props: {
                        label: 'Cards',
                        optionLabel: o => {
                            if (!o) {
                                return null;
                            }

                            if (o.shortName) {
                                return o.shortName;
                            }

                            return `${o.firstName} ${o.lastName}`;
                        },
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'card'
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'medium',
                    props: {
                        label: 'Medium',
                        optionLabel: o => o.name,
                        multiple: false
                    },
                    defaultValue: null,
                    optionsProvider: 'medium'
                }
            ]
        ],
        providers: {
            card: CardModel.list,
            medium: MediumModel.list
        }
    }
}
