import translateFieldsHelper from '../helpers/translateFieldsHelper';
import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper';

const transform = group => ({
    ...group,
    players: group.players ? group.players.map(i => translateFieldsHelper(i, ['type'])) : []
});

const reverseTransform = group => ({
    ...removeExtraSubFieldsHelper(group, ['game']),
    players: group.players ? group.players.map(p => removeExtraSubFieldsHelper(p, ['player', 'type'])) : []
});

export { transform, reverseTransform };
