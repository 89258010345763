import React, { useState } from 'react';
import Collection from './Collection';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Input from './Input';
import Backdrop from '../Backdrop';
import OpponentScorerModel from '../../models/OpponentScorerModel';
import DatePicker from './DatePicker';

export default props => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [saving, setSaving] = useState(false);

    const handleRequireScorerAdd = (val) => {
        setLastName(val.firstName);
        setDialogOpened(true);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();
        setSaving(true);
        await OpponentScorerModel.create({ firstName, lastName, birthDate });
        await props.onRequireRefresh();
        setSaving(false);
        setDialogOpened(false);
        setFirstName('');
        setLastName('');
    };

    return <>
        <Collection {...props} itemProps={{ onRequireScorerAdd: handleRequireScorerAdd }} />
        <Dialog open={dialogOpened} onClose={() => setDialogOpened(false)}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Add a new opponent scorer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The opponent scorer you're looking for is missing? Add it here.
                    </DialogContentText>
                    <Input
                        autoFocus
                        margin="dense"
                        size="small"
                        value={firstName}
                        onChange={setFirstName}
                        label="First name"
                    />
                    <Input
                        autoFocus
                        margin="dense"
                        size="small"
                        value={lastName}
                        onChange={setLastName}
                        label="Last name"
                    />
                    <DatePicker label="Birth date" value={birthDate} onChange={setBirthDate} />
                </DialogContent>
                <DialogActions>
                    { !saving ? (
                        <Button type="submit" color="primary">
                            Add
                        </Button>
                    ) : null }
                    <Backdrop open={saving} />
                </DialogActions>
            </form>
        </Dialog>
    </>;
};
