import React from 'react';
import { Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default props => {
    return <div
        className={props.className || ''}
        style={{ paddingTop: '2rem', position: 'relative', width: '100%', boxSizing: 'border-box' }}
    >
        <Fab
            style={{
                position: 'absolute',
                right: '0',
                top: '0'
            }}
            size="small"
            aria-label="delete"
            onClick={props.onDelete}
        >
            <CloseIcon />
        </Fab>
        { props.children }
    </div>;
};
