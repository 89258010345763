import React from 'react';
import CollectionItem from './CollectionItem';
import TranslatableInput from './TranslatableInput';
import Input from './Input';

export default props => {
    return <CollectionItem onDelete={props.onDelete}>
        <TranslatableInput
            label="Label"
            value={props.value.label ? props.value.label : [] }
            onChange={v => props.onChange('label', v)}
        />
        <Input
            label="Link"
            value={props.value.link}
            onChange={v => props.onChange('link', v)}
        />
    </CollectionItem>;
};
