import React, { useEffect, useState } from 'react';
import { formDateToApi } from '../../helpers/dateHelper';
import SeasonModel from '../../models/SeasonModel';
import AutocompleteSelect from './AutocompleteSelect';

export default props => {
    const { date, label, optionLabel, options, value, onChange } = props;
    const [externalDate, setExternalDate] = useState(date);

    useEffect(() => setExternalDate(date), [date]);

    useEffect(() => {
        if (date === externalDate) {
            return;
        }

        SeasonModel.guessSeason(formDateToApi(date)).then(onChange);
    }, [date, externalDate, onChange]);

    return <AutocompleteSelect
        value={value}
        label={ label }
        optionLabel={ optionLabel }
        options={ options }
        onChange={ onChange }
        required={true}
    />;
};
