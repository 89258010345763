import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import { NewsIcon } from '../components/Icons';
import MediumModel from '../models/MediumModel';

export default {
    baseUrl: '/media',
    icon: NewsIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Media',
        create: 'Add a new medium',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'name'
        }
    },
    calls: {
        list: MediumModel.list,
        create: MediumModel.create,
        update: MediumModel.edit,
        delete: MediumModel.remove,
        read: MediumModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'name',
                    props: {
                        label: 'Name'
                    }
                }
            ]
        ]
    }
};
