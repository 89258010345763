import { get } from 'lodash';
import GameModel from '../models/GameModel';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import GameGroupModel from '../models/GameGroupModel';
import GameGroupPlayers from '../components/form/GameGroupPlayers';
import { UiUserGroupIcon } from '../components/Icons';

export default {
    baseUrl: '/game-groups',
    icon: UiUserGroupIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Games groups',
        create: 'Add a new game group',
        update: 'Update %sum_up%',
        delete: 'Delete %sum_up%',
        vars: {
            '%sum_up%': 'game.sumUp'
        }
    },
    calls: {
        list: GameGroupModel.list,
        create: GameGroupModel.create,
        update: GameGroupModel.edit,
        delete: GameGroupModel.remove,
        read: GameGroupModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'game.sumUp',
                title: 'Game sum up'
            }
        ],
        filters: [
            {
                title: 'Sum up',
                filter: (item, value) => get(item, 'game.sumUp').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    form: {
        blocks: [
            [
                {
                    component: AutocompleteSelect,
                    field: 'game',
                    props: {
                        label: 'Game',
                        optionLabel: o => o ? o.sumUp : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'game'
                }
            ],
            [
                {
                    component: GameGroupPlayers,
                    field: 'players',
                    props: {
                        label: 'Players'
                    },
                    defaultValue: []
                }
            ]
        ],
        providers: {
            game: GameModel.list
        }
    }
}
