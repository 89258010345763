import { get } from 'lodash';
import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import { defaultDate } from '../helpers/dateHelper';
import DatePicker from '../components/form/DatePicker';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import NationalityModel from '../models/NationalityModel';
import Checkbox from '../components/form/Checkbox';
import TranslatableInput from '../components/form/TranslatableInput';
import LeaderModel from '../models/LeaderModel';
import LeaderCareerRoles from '../components/form/LeaderCareerRoles';
import { BusinessmanIcon } from '../components/Icons';
import ArticleModel from '../models/ArticleModel'

export default {
    baseUrl: '/managers',
    icon: BusinessmanIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TRANSLATOR'],
    titles: {
        list: 'Managers',
        create: 'Add a new manager',
        update: 'Update %first_name% %last_name%',
        delete: 'Delete %first_name% %last_name%',
        vars: {
            '%first_name%': 'firstName',
            '%last_name%': 'lastName'
        }
    },
    calls: {
        list: LeaderModel.list,
        create: LeaderModel.create,
        update: LeaderModel.edit,
        delete: LeaderModel.remove,
        read: LeaderModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'firstName',
                title: 'First name'
            },
            {
                field: 'lastName',
                title: 'Last name'
            }
        ],
        filters: [
            {
                title: 'First name',
                filter: (item, value) => get(item, 'firstName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Last name',
                filter: (item, value) => get(item, 'lastName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    create: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    delete: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name',
                        required: true
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name',
                        required: true
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'completeName',
                    props: {
                        label: 'Complete name'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: DatePicker,
                    field: 'birthDate',
                    props: {
                        label: 'Birth date'
                    },
                    defaultValue: defaultDate(new Date()),
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: DatePicker,
                    field: 'deathDate',
                    props: {
                        label: 'Death date'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'birthTown',
                    props: {
                        label: 'Birth town'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'birthCountry',
                    props: {
                        label: 'Birth country',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'nationality',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'nationalities',
                    props: {
                        label: 'Nationalities',
                        optionLabel: o => o ? o.name : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'nationality',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'contract',
                    props: {
                        label: 'End of contract'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: TranslatableInput,
                    field: 'description',
                    props: {
                        label: 'Description',
                        multiline: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: LeaderCareerRoles,
                    field: 'career',
                    props: {
                        label: 'Career'
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'article',
                    props: {
                        label: 'Article',
                        optionLabel: a => a ? a.title : '',
                        multiple: false
                    },
                    defaultValue: null,
                    optionsProvider: 'article',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ]
        ],
        providers: {
            nationality: NationalityModel.list,
            article: ArticleModel.list
        }
    }
}
