import config from '../config/config.json';

export default {
    create(file) {
        const data = new FormData();
        const token = localStorage.getItem('token');

        data.append("file", file, file.name);
        return fetch(
            `${config.api}/pictures`,
            {
                method: 'POST',
                body: data,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(response => response.json());
    }
};
