import React from 'react'
import { Paper, Typography } from '@material-ui/core'

import Input from './Input'

export default props => {
    const onContentChanged = (newValue) => onPropertyChanged('content', newValue);

    const onLinkChanged = (newValue) => onPropertyChanged('link', newValue);

    const onLinkTextChanged = (newValue) => onPropertyChanged('linkText', newValue);

    const onPropertyChanged = (property, newValue) => {
        props.onChange({
            ...props.value,
            [property]: newValue
        });
    }

    return <Paper
        elevation={1}
        style={{
            width: '100%',
            padding: '1rem',
            boxSizing: 'border-box',
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)',
            marginTop: '1rem'
        }}
    >
        <Typography variant="caption">Banner</Typography>
        <Input value={props.value.content} label="Banner content" onChange={onContentChanged} />
        <Input value={props.value.link} label="Banner link" onChange={onLinkChanged} />
        <Input value={props.value.linkText} label="Banner link text" onChange={onLinkTextChanged} />
    </Paper>
}
