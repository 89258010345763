import React from 'react';
import TextField from '@material-ui/core/TextField';
import TimeInput from './TimeInput';
import {InputAdornment} from '@material-ui/core';

export default props => <div style={props.style ? props.style : { display: 'flex', width: '100%' }}>
    <TimeInput
        style={{ width: '100%', margin: '0' }}
        label={props.label}
        value={props.timeValue}
        size={props.size ? props.size : 'medium'}
        onChange={v => props.onTimeChange(v)}
    />
    <TextField
        style={{ width: '100%', margin: '0' }}
        label="Extra minute"
        value={props.extraTimeValue}
        size={props.size ? props.size : 'medium'}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">+</InputAdornment>
            ),
        }}
        onChange={e => props.onExtraTimeChange(e.target.value)}
    />
</div>;
