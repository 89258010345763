import React from 'react';
import { Fab, Paper, Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import AddIcon from '@material-ui/icons/Add';

export default props => {
    const handleDelete = i => {
        const newValue = cloneDeep(props.value);

        newValue.splice(i, 1);

        props.onChange(newValue);
    };

    const handleChange = (i, property, value) => {
        const newValue = cloneDeep(props.value);

        newValue[i][property] = value;

        props.onChange(newValue);
    };

    const handleAdd = () => {
        const newValue = cloneDeep(props.value);

        newValue.push(props.itemTemplate);

        props.onChange(newValue);
    };

    const ItemComponent = props.itemComponent;

    return <Paper
        elevation={1}
        className={ props.inline ? 'autogrid has-gutter' : '' }
        style={{
            width: '100%',
            padding: '1rem',
            boxSizing: 'border-box',
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)',
            marginTop: '1rem'
        }}
    >
        <Typography variant="caption">{ props.label }</Typography>
        { props.value.map((item, i) => (
            <Paper
                key={i}
                elevation={1}
                style={{
                    width: '100%',
                    padding: '1rem',
                    marginBottom: '0.5rem',
                    boxSizing: 'border-box',
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)'
                }}
            >
                <ItemComponent
                    { ...props.itemProps }
                    parentProps={props}
                    value={item}
                    onChange={(p, v) => handleChange(i, p, v)}
                    onDelete={() => handleDelete(i)}
                />
            </Paper>
        )) }
        <div style={{ marginTop: '1rem' }}>
            <Fab size="small" color="primary" aria-label="add" onClick={handleAdd}>
                <AddIcon />
            </Fab>
        </div>
    </Paper>;
};
