import removeExtraSubFieldsHelper from '../helpers/removeExtraSubFieldsHelper'
import translateFieldsHelper from '../helpers/translateFieldsHelper'

const transform = championshipOutcome =>  championshipOutcome === null ? null : translateFieldsHelper(championshipOutcome, ['rankings']);

const reverseTransform = championshipOutcome => removeExtraSubFieldsHelper(
    championshipOutcome,
    ['fromSeason', 'toSeason', 'rankings', 'competition', 'leadsToCompetition']
);

export { transform, reverseTransform };
