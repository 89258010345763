import RestModel from './RestModel';
import { reverseTransform } from '../transformers/transferRumorTransformer';

const resourceName = 'transfer-rumors';

export default {
    create: resource => RestModel.create(resourceName, reverseTransform(resource)),

    edit: resource => RestModel.edit(resourceName, reverseTransform(resource)),

    list: () => RestModel.list(resourceName),

    read: id => RestModel.read(resourceName, id),

    remove: id => RestModel.remove(resourceName, id),
};
