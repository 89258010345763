import { get } from 'lodash';
import Input from '../components/form/Input';
import PlayerModel from '../models/PlayerModel';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import TransferRumorModel from '../models/TransferRumorModel';
import TransferRumorDirectionSwitch from '../components/form/TransferRumorDirectionSwitch';
import ClubModel from '../models/ClubModel';
import Checkbox from '../components/form/Checkbox';
import { MicIcon } from '../components/Icons';

export default {
    baseUrl: '/transfer-rumors',
    icon: MicIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Transfer rumors',
        create: 'Add a new transfer rumor',
        update: 'Update rumor for %first_name% %last_name%',
        delete: 'Delete rumor for %first_name% %last_name%',
        vars: {
            '%first_name%': 'firstName',
            '%last_name%': 'lastName'
        }
    },
    calls: {
        list: TransferRumorModel.list,
        create: TransferRumorModel.create,
        update: TransferRumorModel.edit,
        delete: TransferRumorModel.remove,
        read: TransferRumorModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'firstName',
                title: 'First name'
            },
            {
                field: 'lastName',
                title: 'Last name'
            }
        ],
        filters: [
            {
                title: 'First name',
                filter: (item, value) => get(item, 'firstName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Last name',
                filter: (item, value) => get(item, 'lastName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name'
                    }
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name'
                    }
                },
                {
                    component: TransferRumorDirectionSwitch,
                    field: 'direction',
                    defaultValue: true
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'player',
                    props: {
                        label: 'Database player',
                        optionLabel: o => o ? `${o.firstName} ${o.lastName}` : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'player'
                },
                {
                    component: AutocompleteSelect,
                    field: 'clubs',
                    props: {
                        label: 'Clubs',
                        optionLabel: o => o ? o.shortName : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'club'
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'confirmed',
                    props: {
                        label: 'Confirmed?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            player: PlayerModel.list,
            club: ClubModel.list
        }
    }
}
