import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default (props) => {
    const classes = useStyles();

    return <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<FileCopyIcon />}
        onClick={props.onClick}
    >
        Copy next game line-up
    </Button>;
};
