import Input from '../components/form/Input';
import ArticleCategoryModel from '../models/ArticleCategoryModel';
import { PapersIcon } from '../components/Icons';

export default {
    baseUrl: '/article-categories',
    icon: PapersIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Article categories',
        create: 'Add a new article category',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'name'
        }
    },
    calls: {
        list: ArticleCategoryModel.list,
        create: ArticleCategoryModel.create,
        update: ArticleCategoryModel.edit,
        delete: ArticleCategoryModel.remove,
        read: ArticleCategoryModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: Input,
                    field: 'name',
                    props: {
                        label: 'Name'
                    }
                }
            ]
        ]
    }
};
