import Input from '../components/form/Input';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import TranslatableInput from '../components/form/TranslatableInput';
import RoleModel from '../models/RoleModel';
import RoleScreenModel from '../models/RoleScreenModel';
import { Briefcase1Icon } from '../components/Icons';
import Checkbox from '../components/form/Checkbox';

export default {
    baseUrl: '/roles',
    icon: Briefcase1Icon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Managers roles',
        create: 'Add a new manager role',
        update: 'Update manager role',
        delete: 'Delete manager role'
    },
    calls: {
        list: RoleModel.list,
        create: RoleModel.create,
        update: RoleModel.edit,
        delete: RoleModel.remove,
        read: RoleModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false
    },
    form: {
        blocks: [
            [
                {
                    component: TranslatableInput,
                    field: 'name',
                    props: {
                        label: 'Name',
                        multiline: false
                    },
                    defaultValue: []
                },
                {
                    component: Input,
                    field: 'level',
                    props: {
                        label: 'Hierarchy level'
                    }
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'screens',
                    props: {
                        label: 'Displayed screens',
                        optionLabel: o => o ? o.displayName : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'roleScreen'
                },
                {
                    component: Checkbox,
                    field: 'coachRole',
                    props: {
                        label: 'Is it a coach role?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            roleScreen: RoleScreenModel.list
        }
    }
}
