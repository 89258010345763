const transform = seasonBounding => ({
    ...seasonBounding,
    fromYear: seasonBounding.fromYear.toString(),
    toYear: seasonBounding.toYear.toString(),
    startMonth: seasonBounding.startMonth.toString(),
    startDay: seasonBounding.startDay.toString(),
    endMonth: seasonBounding.endMonth.toString(),
    endDay: seasonBounding.endDay.toString()
});

const reverseTransform = seasonBounding => ({
    ...seasonBounding,
    fromYear: Number(seasonBounding.fromYear),
    toYear: Number(seasonBounding.toYear),
    startMonth: Number(seasonBounding.startMonth),
    startDay: Number(seasonBounding.startDay),
    endMonth: Number(seasonBounding.endMonth),
    endDay: Number(seasonBounding.endDay)
});

export { transform, reverseTransform };
