import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import ContributorModel from '../models/ContributorModel';
import { FunkyManIcon } from '../components/Icons';

export default {
    baseUrl: '/contributors',
    icon: FunkyManIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Contributors',
        create: 'Add a new contributor',
        update: 'Update %name%',
        delete: 'Delete %name%',
        vars: {
            '%name%': 'name'
        }
    },
    calls: {
        list: ContributorModel.list,
        create: ContributorModel.create,
        update: ContributorModel.edit,
        delete: ContributorModel.remove,
        read: ContributorModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'name',
                title: 'Name'
            }
        ],
        paginate: false

    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'name',
                    props: {
                        label: 'Name'
                    }
                },
                {
                    component: Input,
                    field: 'role',
                    props: {
                        label: 'Role'
                    }
                },
                {
                    component: Input,
                    field: 'job',
                    props: {
                        label: 'Job'
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'twitter',
                    props: {
                        label: 'Twitter account'
                    }
                },
                {
                    component: Input,
                    field: 'website',
                    props: {
                        label: 'Website',
                    }
                }
            ],
            [
                {
                    component: Input,
                    field: 'displayPriority',
                    props: {
                        label: 'Display priority'
                    }
                }
            ]
        ]
    }
};
