import config from '../config/config.json';
import moment from 'moment-timezone';

const query = async (url, options) => {
    const token = localStorage.getItem('token');

    if (!token && url !== 'login') {
        window.location.href = '/login';
        return;
    }

    const requestOptions = {
        ...(options || {}),
        headers: {
            ...(options && options.headers ? options.headers : {}),
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            'X-Timezone': moment.tz.guess()
        }
    };
    const response = await fetch(`${config.api}/${url}`, requestOptions);

    if (response.status === 401) {
        window.location.href = '/login';
    }

    return response.status === 204 ? null : response.json();
};

export default {
    create: (resourceName, resource) => query(`${resourceName}`, {
        method: 'POST',
        body: JSON.stringify(resource),
        headers: {
            'Content-Type': 'application/json'
        }
    }),

    edit: (resourceName, resource) => query(`${resourceName}/${resource.id}`, {
        method: 'PUT',
        body: JSON.stringify(resource),
        headers: {
            'Content-Type': 'application/json'
        }
    }),

    update: (url) => query(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        }
    }),

    list: resourceName => query(`${resourceName}`),

    read: (resourceName, id) => query(`${resourceName}/${id}`),

    remove: (resourceName, id) => query(`${resourceName}/${id}`, { method: 'DELETE' })
};
