import React, { useState } from 'react';
import './App.css';
import UserContext from './contexts/UserContext';
import AuthWrapper from './components/AuthWrapper';
import Backoffice from './components/Backoffice';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


const App = () => {
    const userHook = useState(null);

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#ab3013'
            }
        }
    });

    return <UserContext.Provider value={userHook}>
        <AuthWrapper>
            <ThemeProvider theme={theme}>
                <Backoffice />
            </ThemeProvider>
        </AuthWrapper>
    </UserContext.Provider>;
};

export default App;
